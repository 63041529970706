
import { $t } from './services/i18n.js';

export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": $t('dashboard'),
    "icon": "pi pi-palette",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/certificates",
    "label": $t('certificates'),
    "icon": "pi pi-verified",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/certificatetypes",
    "label": $t('certificateTypes'),
    "icon": "pi pi-copy",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/complaints",
    "label": $t('complaints'),
    "icon": "pi pi-book",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/complaintstatuses",
    "label": $t('complaintStatuses'),
    "icon": "pi pi-check-circle",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/complainttypes",
    "label": $t('complaintTypes'),
    "icon": "pi pi-circle",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/places",
    "label": $t('places'),
    "icon": "pi pi-compass",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/placetypes",
    "label": $t('placeTypes'),
    "icon": "pi pi-globe",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/roles",
    "label": $t('roles'),
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/users",
    "label": $t('users'),
    "icon": "pi pi-user",
    "iconcolor": "",
    "target": "",
    
  }
],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    locales: {
  "en-US": "English",
  "hi": "Hindi"
}
}
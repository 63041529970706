
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//applogs routes

//certificates routes
			{
				path: '/certificates/:fieldName?/:fieldValue?',
				name: 'certificateslist',
				component: () => import('./pages/certificates/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/certificates/view/:id', 
				name: 'certificatesview', 
				component: () => import('./pages/certificates/view.vue'), 
				props: true
			},
		
			{ 
				path: '/certificates/add', 
				name: 'certificatesadd', 
				component: () => import('./pages/certificates/add.vue'), 
				props: true
			},
	
			{ 
				path: '/certificates/edit/:id', 
				name: 'certificatesedit', 
				component: () => import('./pages/certificates/edit.vue'), 
				props: true
			},
		

//certificatestatuses routes
			{
				path: '/certificatestatuses/:fieldName?/:fieldValue?',
				name: 'certificatestatuseslist',
				component: () => import('./pages/certificatestatuses/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/certificatestatuses/view/:id', 
				name: 'certificatestatusesview', 
				component: () => import('./pages/certificatestatuses/view.vue'), 
				props: true
			},
		
			{ 
				path: '/certificatestatuses/add', 
				name: 'certificatestatusesadd', 
				component: () => import('./pages/certificatestatuses/add.vue'), 
				props: true
			},
	
			{ 
				path: '/certificatestatuses/edit/:id', 
				name: 'certificatestatusesedit', 
				component: () => import('./pages/certificatestatuses/edit.vue'), 
				props: true
			},
		

//certificatetypes routes
			{
				path: '/certificatetypes/:fieldName?/:fieldValue?',
				name: 'certificatetypeslist',
				component: () => import('./pages/certificatetypes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/certificatetypes/view/:id', 
				name: 'certificatetypesview', 
				component: () => import('./pages/certificatetypes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/certificatetypes/add', 
				name: 'certificatetypesadd', 
				component: () => import('./pages/certificatetypes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/certificatetypes/edit/:id', 
				name: 'certificatetypesedit', 
				component: () => import('./pages/certificatetypes/edit.vue'), 
				props: true
			},
		

//complaints routes
			{
				path: '/complaints/:fieldName?/:fieldValue?',
				name: 'complaintslist',
				component: () => import('./pages/complaints/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/complaints/view/:id', 
				name: 'complaintsview', 
				component: () => import('./pages/complaints/view.vue'), 
				props: true
			},
		
			{ 
				path: '/complaints/add', 
				name: 'complaintsadd', 
				component: () => import('./pages/complaints/add.vue'), 
				props: true
			},
	
			{ 
				path: '/complaints/edit/:id', 
				name: 'complaintsedit', 
				component: () => import('./pages/complaints/edit.vue'), 
				props: true
			},
		

//complaintstatuses routes
			{
				path: '/complaintstatuses/:fieldName?/:fieldValue?',
				name: 'complaintstatuseslist',
				component: () => import('./pages/complaintstatuses/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/complaintstatuses/view/:id', 
				name: 'complaintstatusesview', 
				component: () => import('./pages/complaintstatuses/view.vue'), 
				props: true
			},
		
			{ 
				path: '/complaintstatuses/add', 
				name: 'complaintstatusesadd', 
				component: () => import('./pages/complaintstatuses/add.vue'), 
				props: true
			},
	
			{ 
				path: '/complaintstatuses/edit/:id', 
				name: 'complaintstatusesedit', 
				component: () => import('./pages/complaintstatuses/edit.vue'), 
				props: true
			},
		

//complainttypes routes
			{
				path: '/complainttypes/:fieldName?/:fieldValue?',
				name: 'complainttypeslist',
				component: () => import('./pages/complainttypes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/complainttypes/view/:id', 
				name: 'complainttypesview', 
				component: () => import('./pages/complainttypes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/complainttypes/add', 
				name: 'complainttypesadd', 
				component: () => import('./pages/complainttypes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/complainttypes/edit/:id', 
				name: 'complainttypesedit', 
				component: () => import('./pages/complainttypes/edit.vue'), 
				props: true
			},
		

//places routes
			{
				path: '/places/:fieldName?/:fieldValue?',
				name: 'placeslist',
				component: () => import('./pages/places/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/places/view/:id', 
				name: 'placesview', 
				component: () => import('./pages/places/view.vue'), 
				props: true
			},
		
			{ 
				path: '/places/add', 
				name: 'placesadd', 
				component: () => import('./pages/places/add.vue'), 
				props: true
			},
	
			{ 
				path: '/places/edit/:id', 
				name: 'placesedit', 
				component: () => import('./pages/places/edit.vue'), 
				props: true
			},
		

//placetypes routes
			{
				path: '/placetypes/:fieldName?/:fieldValue?',
				name: 'placetypeslist',
				component: () => import('./pages/placetypes/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/placetypes/view/:id', 
				name: 'placetypesview', 
				component: () => import('./pages/placetypes/view.vue'), 
				props: true
			},
		
			{ 
				path: '/placetypes/add', 
				name: 'placetypesadd', 
				component: () => import('./pages/placetypes/add.vue'), 
				props: true
			},
	
			{ 
				path: '/placetypes/edit/:id', 
				name: 'placetypesedit', 
				component: () => import('./pages/placetypes/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'usersuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
//Email verify routes
			{ 
				path: '/index/verifyemail', 
				name: 'verifyemail', 
				component: () => import('./pages/index/verifyemail.vue'), 
				props: true
			},
			{ 
				path: '/index/emailverified', 
				name: 'emailverified', 
				component: () => import('./pages/index/emailverified.vue'), 
				props: true
			},
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		
		//authenticate user
		if (authRequired && !user) {
			//user is not logged. redirect to login
			return next({ path: '/',  query: { nexturl: to.fullPath } });
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

		//close dialog from previous page
		//store.dispatch('app/closeDialogs');

		//continue to specified route
		next();
	});

	return router;
}
